import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './index.scss';

const AllFaq = (props) => {
	const { title, answer, id } = props;
	const [ button, setbutton ] = useState(false);
	const [ isActive, setIsActive ] = useState(false);
	return (
		
			<div className="row mx-0 faq-data" key={id}>
				<div
					className="single-faq"
					onClick={() => {
						setIsActive(!isActive);
						setbutton(!button);
					}}
				>
					<h6>
						<span className="numbering rounded-circle">{id}</span>
						<span className="title">
							{title}
							<span className="button">
								<motion.img
									src="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887365/images/icons/Collapse_qb6hbv.svg"
									alt="collapse icon svg"
									animate={{ rotate: button ? 180 : 0 }}
								/>
							</span>
						</span>
					</h6>
				</div>
				{isActive && <motion.div className="details">{answer}</motion.div>}
			</div>
	);
};

export default AllFaq;
