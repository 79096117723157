import React, { Component } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import './index.scss';

class Reel extends Component {
	handleSubmit = () => {
		this.props.history.goBack();
		document.getElementById('menu-item').classList.remove('hide-menu');
	};
	componentDidMount() {
		// document.getElementById('demo-vid').play();
		document.getElementById('menu-item').classList.add('hide-menu');
	}

	render() {
		return (
			<div id="demo-reel">
				<div className="cancel-btn">
					<div className="cancel-btn-icon">
						<img
							src="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887366/images/icons/delete_z2te8c.png"
							id="video-cancel"
							alt="cancel button"
							onClick={this.handleSubmit}
						/>
					</div>
				</div>
				<div id="demo-vid" className="col-md-12">
					<Vimeo video="512287804" autoplay />
				</div>
			</div>
		);
	}
}

export default Reel;
