// import logo from './logo.svg';
import './App.scss';
import React, { Component } from "react"
import {Route,BrowserRouter,Switch} from "react-router-dom"

import HomePage from "./pages/home/index"
import About from "./pages/about/index"
import BrandsPage from "./pages/brands/index"
import CareerPage from "./pages/career/index"
import ProjectDetails from "./components/projectDetails/index"
import Menu from "./components/menu/menu"
import Reel from "./components/reel/index"
import PrivacyPage from "./pages/privacy/index"
import HistoryPage from "./pages/History/index"
import LogoIcon from "./components/menu/index.js"
import Logo from "./components/logo/index.js";
import careerDetails from "./components/career/careerDetails"

import WebGl from "./components/webgl/index"
// import Lost from "./components/404/index"

import RouteChangeTracker from "./components/google/index"

import "./components/fonts/font.scss"

class App extends Component {
    render() {
        return (
          <BrowserRouter>
            <RouteChangeTracker />  
            <Logo></Logo>
            <LogoIcon></LogoIcon>
            <Switch>
              <Route exact path="/" component={HomePage}></Route>
              <Route exact path="/menu" component={Menu}></Route>
              <Route path="/reel" component={Reel}></Route>
              <Route path="/about" component={About}></Route>
              <Route path="/career" component={CareerPage}></Route>
              <Route path="/brands" component={BrandsPage}></Route>
              <Route path="/privacy" component={PrivacyPage}></Route>
              <Route path="/history" component={HistoryPage}></Route>
              <Route path="/details/:title" component={ProjectDetails}></Route>
              <Route path="/careerdetails" component={careerDetails}></Route>
            </Switch>
          </BrowserRouter>
        );
    }
}


export default App;
