import {configureStore} from "@reduxjs/toolkit"
import serviceReducer from "./services/index"
import faqReducer from "./faq/index"
import careerReducer from "./careerDetails/careerDetails"

export default configureStore({
    reducer:{
        service:serviceReducer,
        faq:faqReducer,
        career:careerReducer,
    }
})