import React,{Component} from "react"
import AllData from "./allData"
import Footer from "../footer/index"
import "./index.scss"

class Brands extends Component {
  state = {
    data: [
      {
        id: "1",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/Lockup-Color_r8kfem.png",
        imag_desc: "the official logo of GuezShow visual design compnay",
        info:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in... ",
      },
      {
        id: "2",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887318/images/brands/Icon-Main-Color_i4o7un.png",
        imag_desc:
          "the official logo of GuezAgency a creative design company based in rwanda ",
        info:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in... ",
      },
      {
        id: "3",
        image:"https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887317/images/brands/Lockup-Color_2_f91ca4.png",
        imag_desc:
          "the official logo of GuezAcademy a high end vfx school based in Rwanda",
        info:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in... ",
      },
    ],
  };
  render() {
    return (
      <div>
        <div className="container-fluid px-0" id="brands">
          <div className="img-bg"></div>
          <div className="header">
            <h1>
              we are <span className="blue-color"> guez group.</span>
            </h1>
            <h3>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in...&nbsp; &nbsp;
              <span className="blue-color">Learn More</span>
            </h3>
            <h1 className="our-brands">
              our&nbsp;<span className="blue-color">brands.</span>
            </h1>
            <AllData data={this.state.data} />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Brands