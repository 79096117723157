import React,{Component} from "react"

class CareerFaq extends Component{
    render(){
        const {data} =this.props
        const allData=data.map(singleData =>{
            return(
                <div className="col-md-6 question">
                <div className="line">
                    <div className="inside"></div>
                </div>
                <div>
                    <div className="all-content">
                        <div className="top-elements">
                            <h2>{singleData.id}.</h2>
                            <img src={singleData.image} alt={singleData.img_desc} />
                        </div>
                    </div>

                    <div className="content">
                        <h5>{singleData.title}</h5>
                        <h6>
                            {singleData.description}
                        </h6>
                    </div>
                </div>

            </div> 
            )
        })
        return(
            <div class="row mx-0">
                {allData}
            </div>
        )
    }
}

export default CareerFaq