import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom"
import './careerdetail.scss';

const CareerDetails = () => {
	const { career } = useSelector((state) => state.career);
  const history=useHistory()
  
  console.log(career)
	return (
		<div>
      {career.careerTitle?null:history.push("/career")}
			<div className="container-fluid" id="service-details">
				<div className="row mx-0">
					<div className="col-md-12 d-flex justify-content-end close-image-wrapper">
						<img src="http://127.0.0.1:5500/images/cdn/close-icon.svg" alt="" />
					</div>
					<div className="col-md-12 row mx-0 career-details-card">
						<div
							className="col-md-6 career-image"
							style={{
								backgroundImage:
									`url(${career.careerImage})`
							}}
						/>
						<div className="col-md-6 details ">
							<div className="career-title px-0">{career.careerTitle}</div>
							<div className="career-details">
								{career.careerDescription}
							</div>
							<div className="career-requirement px-0">Requirements</div>
              {career.careerRequirement.map((singleData,index)  =>{
                return(<div className="reuquirement d-flex">
								<div className="rounded-circle">{index+1}</div>
								<div className="the-reuquirement">
									{singleData}
								</div>
							</div>)
              })}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CareerDetails;
