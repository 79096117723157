import React, { Component } from "react";
import {Link} from "react-router-dom"

import instagram from "./assets/IG.svg";
import facebook from "./assets/FB.svg";
import twitter from "./assets/TW.svg";
import reel from "./assets/Demo.svg";
import "./index.scss";

class Home extends Component {
  render() {
    return (
      <div>
        <div className="container" id="main-home">
          <div className="top-wrapper"></div>
          <div className="middle-wrapper">
            <div className="header-text">
              <div className="text-messages">
                <h6 className="company-name">
                  We are <span className="bold">Guez Show</span>
                </h6>
                <h1 className="text ">
                  <span className="stroke">
                    For immersive virtual experiences
                  </span>
                </h1>
                <div className="other-social-media">
                  <a
                    href="https://www.facebook.com/GuezShow"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={facebook}
                      className="socio-icon fb-icon-main"
                      alt="facebook icon guez show"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/GuezShow"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={instagram}
                      className="socio-icon ig-icon-main"
                      alt="instagram-icon guezshow"
                    />
                  </a>
                  <a
                    href="https://www.twitter.com/GuezShow"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={twitter}
                      className="socio-icon tw-icon-main"
                      alt="tw-svg-guezshow"
                    />
                  </a>
                  <Link to="/reel">
                  <div
                    className="socio-icon demo-reel dml-icon-main"
                    id="contact-us-btn-id"
                  >
                    The Cliff
                    {/* Demo Reel */}
                  </div>
                  <img
                    src={reel}
                    className="socio-icon dm-icon-main"
                    alt="demo-img-guezShow"
                  />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
