import React, { Component } from 'react';

export default class AllData extends Component {
	render() {
		const allData = this.props.data;
		const extractData = allData.map((singleData) => {
			const flags = singleData.flags;
			const allFlags = flags.map((singleFlag) => {
				return (
					<span key={singleFlag.title}>
						{singleFlag.title}
						<span class="country">
							<img src={singleFlag.image} alt={singleFlag.title} />
						</span>
					</span>
				);
			});
			const numbering = singleData.id % 2;
			console.log(singleData.id);
			if (numbering === 0) {
				return (
					<div className="d-flex row justify-content-between single-story mx-0">
						<div className="col-lg-5" />
						<div className="line-divider">
							<div className="rounded-circle">
								<div className="rounded-circle inner-circle" />
							</div>
							<div className="line" />
						</div>
						<div className="col-lg-5 content">
							<div className="row">
								<div className="col-lg-6">
									<div className="d-flex row justify-content-start">
										<div>
											<div className="line-divider-mobile">
												<div className="rounded-circle">
													<div className="rounded-circle inner-circle" />
												</div>
												<div className="line">
													<div className="inner-line" />
												</div>
											</div>
										</div>
										<div className="content-data">
											<div className="year">{singleData.year}</div>
											<div className="action">{singleData.action}</div>
											<div className="date">{singleData.date}</div>
											<div className="event-details">{singleData.details}</div>
											<div className="location">
												<span className="location-icon">
													<img
														src="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887176/images/history/Group_13_xnwh8s.svg"
														alt=""
													/>
												</span>
												{allFlags}
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="back-img" />
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div className="d-flex row justify-content-between single-story mx-0">
						<div className="col-lg-5 content odd">
							<div className="row">
								<div className="col-lg-6 order-lg-2">
									<div className="d-flex row justify-content-start">
										<div>
											<div className="line-divider-mobile">
												<div className="rounded-circle">
													<div className="rounded-circle inner-circle" />
												</div>
												<div className="line">
													<div className="inner-line" />
												</div>
											</div>
										</div>
										<div className="content-data">
											<div className="year d-flex w-lg-100 justify-content-lg-end">{singleData.year}</div>
											<div className="action d-flex w-lg-100 justify-content-lg-end text-lg-right">{singleData.action}</div>
											<div className="date d-flex w-lg-100 justify-content-lg-end text-lg-right">{singleData.date}</div>
											<div className="event-details d-flex w-lg-100 justify-content-lg-end text-lg-right">{singleData.details}</div>
											<div className="location d-flex w-lg-100 justify-content-lg-end">
												<span className="location-icon">
													<img
														src="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887176/images/history/Group_13_xnwh8s.svg"
														alt=""
													/>
												</span>
												{allFlags}
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 order-lg-1">
									<div className="back-img" />
								</div>
							</div>
						</div>
						<div className="line-divider">
							<div className="rounded-circle">
								<div className="rounded-circle inner-circle" />
							</div>
							<div className="line" />
						</div>
						<div className="col-lg-5" />
					</div>
				);
			}
		});
		return (
			<div>
				<div className="container-fluid history">{extractData}</div>
			</div>
		);
	}
}
