import {createSlice} from "@reduxjs/toolkit"

export const serviceSlice=createSlice({
    name:"counter",
    initialState:{
        activeIndex:0,
    },
    reducers:{
        setIndex:(state,action) =>{
            console.log("ready")
            state.activeIndex=action.payload.value
        }
    }
})

export const {setIndex}=serviceSlice.actions

export default serviceSlice.reducer