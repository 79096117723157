import React, { Component } from 'react'
import History from "../../components/history/index"
import Footer from "../../components/footer/index"
import HeaderImg from "../../components/headerImg/index"
import { Helmet } from 'react-helmet'

export default class HistoryPage extends Component {
    render() {
        return (
          <div>
            <Helmet>
              <meta charSet='utf-8' />
              <title>Our Story - Guez Show</title>
              <meta name="description" content="Guez Show was founded in 2013 from that time many things were done here at GuezShow from creating transform africa summit commercials,creating the 600 movie and other projects see how we have been helping different people create the best movie and comercials that influenced different people." />
            </Helmet>
            <HeaderImg title="Our Story" img="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg" icon="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1622814132/images/headerImg/Icon_Our_Story_xajqha.svg"></HeaderImg>
            <History></History>
            <Footer />
          </div>
        );
    }
}
