import React from 'react';

const AllData = (props) => {
	const { image, title, desc } = props;
	return (
		<div id="service">
			<div className="row mx-0">
				<div className="col-md-8 px-0">
					<img src={image} alt="" className="image" />
				</div>
				<div className="col-md-4 ">
					<h3>{title}</h3>
					<h6>{desc}</h6>
				</div>
			</div>
		</div>
	);
};

export default AllData;
