import React, { Component } from "react"

class AllData extends Component {
    render() {
        const { data } = this.props
        const allData = data.map(singleData => {
            return (
              <div className="card  cycle-item" key={singleData.id}>
                <div className="title-cycle">{singleData.title}</div>
                <div className="icon">
                  <img
                    src={singleData.image}
                    alt="the cycle that guez show use"
                  />
                </div>
                <div className="cycle-details">{singleData.desc}</div>
              </div>
            );
        })
        return (<div className="card-deck row justify-content-center mx-0" id="cycle">
            {allData}
        </div>)
    }
}

export default AllData