import { createSlice } from '@reduxjs/toolkit';

const faqSlice = createSlice({
	name: 'faq',
	initialState: {
		indexClicked: 0
	},
	reducers: {
		setClicked: (state, action) => {
			state.indexClicked = action.payload.value;
		}
	}
});

export const { setClicked } = faqSlice.actions;

export default faqSlice.reducer;
