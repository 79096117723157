import React, { useState } from 'react';
import AllData from './allData';
import './index.scss';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import './buttons.scss';
const Service = () => {
	const [currentSlide, setCurrentSlide] = React.useState(0)
	const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
  })
	const [ state ] = useState({
		data: [
			{
				id: 0,
				title: 'XR Development',
				desc: `We specialize in creating Virtual, Augmented and Mixed Reality products to ensure The Most Immersive Experience using the latest technology. Elevate your user experience by creating Interactive products, presentation.`,
				image: 'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623829464/images/projects/compressed/services/guez-show-services-xr-development_obnsya.jpg'
			},
			{
				id: 1,
				title: 'Animation',
				desc: `Bring your stories to life with Stunning Animation. Whether it’s for film, TV or Social Media, we tell your message woven into stunning visuals to create entertaining and informative animations for your audience`,
				image:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623829464/images/projects/compressed/services/guez-show-services-anmation_t80jbf.jpg'
			},
			{
				id: 2,
				title: 'Game Development',
				desc: `Using our background from Animation and Interactive development, we help you bring your dream game to life. Whether it’s a casual mobile game or a graphics intensive title, we will take your project from initial concept to final deployment.`,
				image:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623829464/images/projects/compressed/services/guez-show-services-game-development_pqd4oo.jpg'
			},
			{
				id: 3,
				title: 'Motion Graphics',
				desc: `Put more style and life into your commercial/infomercial. We turn your brand and products into the most memorable icons for your audience. It’s not just numbers and text for us, it’s about the story behind them too.`,
				image:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623829465/images/projects/compressed/services/guez-show-services-motion-graphics_pjqnpv.jpg'
			},
			{
				id: 4,
				title: '3D Visualization',
				desc: `Visualize your concepts, Architectural Designs. We know all the work that goes into your designs and we match it with impeccable attention to detail to give you the best renders for your audience and investors.`,
				image: 'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623829464/images/projects/compressed/services/guez-show-services-architectural-visualization-shot_bv7nek.jpg'
			}
		]
	});

	return (
		<div>
			<div className="col-md-12 special-container" id="services-beginning">
				<div className="title-text" id="tools-line">
					Service
				</div>
			</div>
			<div ref={sliderRef} className="keen-slider">
				{state.data.map((singleData) => {
					return (
						<div className="keen-slider__slide">
							<AllData
								image={singleData.image}
								title={singleData.title}
								desc={singleData.desc}
							/>
						</div>
					);
				})}
			</div>
			{slider && (
				<div className="dots">
					{[ ...Array(slider.details().size).keys() ].map((idx) => {
						return (
							<button
								key={idx}
								onClick={() => {
									slider.moveToSlideRelative(idx);
								}}
								className={'dot' + (currentSlide === idx ? ' active' : '')}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default Service;
