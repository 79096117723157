import React, { useState } from 'react';
import AllFaq from './allData';
import './index.scss';

const Faq = () => {
	const [ state ] = useState({
		data: [
			{
				id: 1,
				title: 'Why should we seek your services?',
				answer: `At Guez Show, we strive to provide excellent, personalized content. Our team ensures that we craft products that represent your ideas, your message and add value to your brand and business.`
			},
			{
				id: 2,
				title: 'What Services do you offer?',
				answer: `Our services range from 2D animation, 3D animation, Motion Graphics and Product Visualization to more interactive media in Virtual, Augmented and Mixed Reality. Each of these is most suitable for a specific project or a combination of multiple will be used.`
			},
			{
				id: 3,
				title: 'What inputs do you require to complete animation projects?',
				answer: `Whether you provide just an idea or a fully fleshed out concept, we bring it to life while making sure we follow your vision to bring you the most value for your business. We keep you involved in the process but can deliver with minimal guidance.`
			},
			{
				id: 4,
				title: 'What is the cost of an animation?',
				answer: `Once we get a look at your project, we can quickly provide you an estimated cost but longer, more complex projects can take a few hours to get an estimated cost with a comprehensive breakdown of the components.`
			},
			{
				id: 5,
				title: 'I don’t have a big budget. Can I get my project made?',
				answer: `We offer budget-friendly options and guidance that will ensure your idea is executed in the best way possible. We offer you a comprehensive cost breakdown to justify the costs and help you optimize your project accordingly.`
			},
			{
				id: 6,
				title: 'How much time do you take to complete a project?',
				answer: `The duration of the project depends on its complexity. A small project can take one or two weeks, while longer and more complex projects can take 4 weeks or longer. We can help you guide the project to accomodate the time you have. `
			},
			{
				id: 7,
				title: 'How do we contact you? Do we need to visit your studio for the project?',
				answer: `We are available on most platforms of communication and you don’t need to visit our studio. You can contact us via Phone, email and text Us on Social Media. We promise a fast response and keep you involved in the creation process of your ideas.
`
			}
		]
	});
	return (
		<div>
			<div className="col-md-12 special-container" id="faq-beginning">
				<div className="title-text" id="tools-line">
					Frequently asked questions
				</div>
			</div>
			<div className="container" id="faq">
				{state.data.map(({id,title,answer}) =>{
					return (<AllFaq title={title} answer={answer} id={id} key={id}/>)
				})}
			</div>
		</div>
	);
};

export default Faq;
