import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Helmet } from 'react-helmet';
import './index.scss';

class AllProject extends Component {
	render() {
		const Projects = this.props.data;
		const allProjects = Projects.map((project) => {
			return (
				<div className=" card " id="project" key={project.id}>
					<Helmet
						script={[
							helmetJsonLdProp({
								'@context': 'https://schema.org/',
								'@type': 'Movie',
								name: `${project.name}`,
								productionCompany: {
									'@type': 'Organization',
									name: 'GuezShow'
								},
								countryOfOrigin: {
									'@type': 'Country',
									name: 'Rwanda'
								}
							})
						]}
					/>
					<Link to={`/details/${project.name}`}>
						<div className="img-3">
							<div
								className="back-img"
								style={{ backgroundImage: `url(${project.image})` }}
								id="vid-img-1"
							/>
							<div className="project-title" itemProp="name">
								{project.name}
							</div>
						</div>
					</Link>
				</div>
			);
		});
		return (
			<div
				className="row mx-0 justify-content-between"
				data-aos="fade-up"
				id="all-projects"
				itemScope
				itemType="https://schema.org/Movie"
			>
				{allProjects}
			</div>
		);
	}
}

export default AllProject;
