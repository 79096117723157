import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class LogoIcon extends Component {
  state = {
    props: {},
    clicked: false,
  };
  componentDidMount() {
    this.setState({
      props: this.props,
    });
    
  }
  componentDidUpdate(){
    if (document.getElementById("navigation") == null) {
      document
        .getElementById("menu-item")
        .classList.remove("animate-hamburger");
    }
  }
  handleClick = async (e) => {
    if (this.state.clicked) {
      await this.state.props.history.goBack();
      document
        .getElementById("menu-item")
        .classList.remove("animate-hamburger");
      this.setState({
        clicked: false,
      });
    } else {
      await this.state.props.history.push("/menu");
      document.getElementById("menu-item").classList.add("animate-hamburger");
      this.setState({
        clicked: true,
      });
    }

    // if(document.getElementsByClassName("animate-hamburger")){
    //   this.state.props.history.goBack()
    // }
  };
  render() {
    return (
      <div className="menu-icon" id="menu-item" onClick={this.handleClick}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    );
  }
}

export default withRouter(LogoIcon);
