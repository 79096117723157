import React, { Component } from "react";
import "./index.scss";
import FooterWebGl from "./footerWebgl";
import { Link } from "react-router-dom";
import handleSubmit from "./handleSubmit";

class Footer extends Component {
  handleSubmit = handleSubmit;
  render() {
    return (
      <div>
        <FooterWebGl />
        <footer id="footer-content">
          <div className="container" id="footer-items">
            <div className="row justify-content-center">
              <div className="subscribe-text">
                Love Guez Show as much as we do? Sign up for updates and stay in
                the loop!
              </div>
            </div>
            <div className="row justify-content-center subsribe">
              <div className="col-md-10 col-lg-6">
                <form action="/email" id="email-btn">
                  <div className="row form-group">
                    <input
                      className="col-md-9 form-control"
                      name="email"
                      id="email-input"
                      placeholder="Type your email address"
                      type="email"
                      required
                    />
                    <button
                      className="col-md-3 btn btn-danger"
                      type="submit"
                      id="buttonEmail"
                      onClick={this.handleSubmit}
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
                <div className="row justify-content-center">
                  <div
                    className="subscribe-text email-message label label-primary"
                    id="message_sent"
                  ></div>
                </div>
                <div className="row justify-content-center">
                  <div
                    className="subscribe-text email-message label label-danger"
                    id="message_error"
                  ></div>
                </div>
              </div>
            </div>
            <div className="row info">
              <div className="col-md-6 information">
                <img
                  src="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887343/images/footer/Lockup-White_xdmgag.svg"
                  alt="Guez Show official logo"
                />
              </div>
              <div className="col-md-3 information">
                <div>KG 11 Ave</div>
                <div>Ikaze House 2nd Floor</div>
                <div>Gisimenti Remera</div>
                <div>Kigali - Rwanda</div>
              </div>
              <div className="col-md-3 links">
                <Link to="/history">
                  <div>Our Story</div>
                </Link>
                <Link to="/about">
                  <div>About Us</div>
                </Link>
                <a
                  href="https://guezgroup.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>Brands</div>
                </a>
                <Link to="/career">
                  <div>Careers</div>
                </Link>
              </div>
            </div>
            <div className="row info">
              <div className="col-md-3 order-md-2">
                <div>+250 78 8488 833</div>
                <div>info@guezshow.com</div>
                <div>career@guezshow.com</div>
              </div>
              <div className="col-md-2 order-md-3">
                <div>Connect with us</div>
                <div>
                  <br />
                </div>
                <div>
                  <div className="row justify-content-md-between mx-0">
                    <a
                      href="https://www.facebook.com/GuezShow"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887186/images/icons/FB_lzxvhv.svg"
                        className="icon"
                        alt=""
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/GuezShow"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887187/images/icons/IG_a2edrb.svg"
                        className="icon"
                        alt=""
                      />
                    </a>
                    <a
                      href="https://www.twitter.com/GuezShow"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887187/images/icons/TW_icisf8.svg"
                        className="icon"
                        alt=""
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/GuezShow"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887188/images/icons/YT_dftzsd.svg"
                        className="icon"
                        alt=""
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/GuezShow"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887369/images/icons/IN_kou5gd.svg"
                        className="icon"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 order-md-1">
                <a href="privacy.html">
                  <div className="privacy-line">
                    Privacy Policy | Terms of Use
                  </div>
                </a>
                <div>
                  Copyright © 2013 -{new Date().getFullYear()}
                  <a href="index.html"> Guez Show. </a>{" "}
                  <span>All rights reserved.</span>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
