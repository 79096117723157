import React, { Component } from 'react';
import AllData from './allData';
import './index.scss';

export default class History extends Component {
	state = {
		data: [
			{
				id: '0',
				year: '2013',
				action: 'Founded',
				date: 'June 2013',
				details: `On 11 June 2013 Guez Show was founded with specific goals of raising production value in areas of advertisements, film and overall content production in Rwanda and the region. Our services leaned heavily into Graphics & Motion Design and would soon grow to cover more areas of content production.`,
				flags: [
					{
						title: 'Kigali - Rwanda ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887179/images/history/rwanda-flag_zhwpnr.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '1',
				year: '2013',
				action: 'DCP',
				date: '11 June 2013',
				details: `At birth Digital Cinema Package (DCP) became the first service that allowed Guez Show to enter local advertisement while working with Century Cinema (Kigali) and later offered DCP services to other local filmmakers who needed to submit their films to various international film festivals.
`,
				flags: [
					{
						title: 'Kigali - Rwanda ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887179/images/history/rwanda-flag_zhwpnr.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '2',
				year: '2013',
				action: 'Transform Africa Summit 2013',
				date: '28 - 31 October 2013',
				details: `A couple of months after birth Guez Show had an opportunity of working on the first ever Transform Africa Summit by providing all digital promotional content for the event. This allowed Guez Show to undertake its first big project since its conception, giving us credibility for bigger projects.
`,
				flags: [
					{
						title: 'Kigali - Rwanda ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887179/images/history/rwanda-flag_zhwpnr.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '3',
				year: '2015',
				action: 'Transform Africa Summit 2015',
				date: '19 - 21 October 2015',
				details: `Transform Africa Summit 2015 was pivotal for Guez Show in all aspects of business and experience. This project pushed our delivery volume capacity to meet the growing demands and tight deadlines. With 2 years of experience this project worked as a benchmark for our growing team’s capacity.`,
				flags: [
					{
						title: 'Kigali - Rwanda ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887179/images/history/rwanda-flag_zhwpnr.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '4',
				year: '2016',
				action: 'Graduation from KLAB',
				date: '31 January 2016',
				details: `Guez Show graduated from KLAB after being incubated there for almost 3 years. Guez Show went on to become a fully independent and sustainable business bringing quality content in the Rwandan ecosystem  and acquire more business while boosting our competitive nature on the market.`,
				flags: [
					{
						title: 'Kigali - Rwanda ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887179/images/history/rwanda-flag_zhwpnr.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '5',
				year: '2016',
				action: 'CIAT Program',
				date: '11 - 23 October 2016',
				details: `Selected by Rwanda ICT Chamber, Represented by the founder Guez Show was among the 2016 CIAT Program by KOICA where we received training in ICT Center Operation, Education Capacity Development and business. This opened the door to our business development side.`,
				flags: [
					{
						title: 'Seoul - South Korea ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887360/images/history/south-korea-flag_webl8r.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '6',
				year: '2017',
				action: 'ITU Telecom World 2017',
				date: '25 - 28 September 2017',
				details: `Guez Show had its first International exhibition at ITU Telecom World 2017 in Busan, South Korea. ITU Telecom organizes an annual global tech event for governments, industry and SMEs to exhibit innovative solutions, network, share knowledge and use the power of technology to create a better digital future.`,
				flags: [
					{
						title: 'Busan - South Korea ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887360/images/history/south-korea-flag_webl8r.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '7',
				year: '2018',
				action: 'NAB Show 2018',
				date: '25 - 28 April 2018',
				details: `Guez Show attended NAB Show which is the premier marketplace for media and entertainment, it is where the global content economy meets business and big ideas are born. We had opportunities to meet and interact with world industry leaders that taught and inspired us from the very beginning.`,
				flags: [
					{
						title: 'Las Vegas - USA',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887361/images/history/united-states-of-america-flag_aw5u4u.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '8',
				year: '2018',
				action: 'Viva Technology 2018',
				date: '25 - 28 May 2018',
				details: `Along with other Rwandan startups and companies, Guez Show was among the exhibitors at Viva Technology 2018, led by His Excellency President Paul Kagame we were given a platform to showcase our work, represent our country and interact/exchange with European businesses and startups.`,
				flags: [
					{
						title: 'Paris - France  ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887348/images/history/france-flag_b51pih.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '9',
				year: '2019',
				action: 'Stanford Seed',
				date: 'January - December 2019',
				details: `Being admitted into Stanford Seed was a game changer to us because it demystified all business challenges and gave us practical tools to solve them.The Stanford Institute for Innovation in Developing Economies, partners with entrepreneurs in emerging markets to build thriving enterprises.
`,
				flags: [
					{
						title: 'Kenya ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887176/images/history/kenya-flag_xkjqax.svg'
					},
					{
						title: 'Ethiopia',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887175/images/history/ethiopia-flag_f7pmeo.svg'
					},
					{
						title: ' Rwanda',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887179/images/history/rwanda-flag_zhwpnr.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '10',
				year: '2019',
				action: 'Transform Africa Summit 2019',
				date: '11 June 2019',
				details: `We had an opportunity to work with Transform Africa Summit 2019. This time not only working on TAS but also as exhibitors in the event. As a mature company this gave us an opportunity to showcase our work and connect with people from different sectors of business in Rwanda and around the world.`,
				flags: [
					{
						title: 'Kigali - Rwanda ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887179/images/history/rwanda-flag_zhwpnr.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '11',
				year: '2019',
				action: 'Viva Technology 2019',
				date: '11 June 2019',
				details: `We participated in the subsequent (in the row) Viva Technology where we had many offerings exhibitions in our product range and services. We represented Rwanda in this event where we created business relationships that allow us to create and export our work for international markets.`,
				flags: [
					{
						title: 'Paris - France ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887348/images/history/france-flag_b51pih.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '12',
				year: '2019',
				action: 'eFounders Fellowship Program',
				date: '11 June 2019',
				details: `Held at Alibaba Group HQ in Hangzhou, the eFounders fellowship program is provided by Alibaba business school in partnership with the UN Conference on Trade and Development (UNCTAD). We learned much about the China Business ecosystem and how to create or enter new markets by Alibaba founders and senior executives.`,
				flags: [
					{
						title: 'Hangzhou - China ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887175/images/history/china-flag_h19lgc.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '13',
				year: '2019',
				action: 'The 600',
				date: '11 June 2019',
				details: `The dramatic and little known story of a group of RPA soldiers trapped behind enemy lines at the outset of the Genocide against the Tutsi in Rwanda in 1994. Eyewitness accounts of their counterattack against a much larger force, and the daring rescues they made of civilians while under fire.`,
				flags: [
					{
						title: 'Kigali - Rwanda ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887179/images/history/rwanda-flag_zhwpnr.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '14',
				year: '2019',
				action: 'Guez Group Founded',
				date: '31 July 2019',
				details: `Since its birth, Guez Show has provided content and creative services for both film, advertisement and education sectors. Due to our ever growing capacities, services and products, it was essential that we properly position ourselves on the market and clearly communicate who we are and what we do.

We created Guez Agency to carry on marketing and communication, Guez Academy which is a social enterprise to train Rwandan youth in our core capabilities, we turned Guez Show into a Production Company and created Guez Group to hold together the three entities. 
`,
				flags: [
					{
						title: 'Kigali - Rwanda ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887179/images/history/rwanda-flag_zhwpnr.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '15',
				year: '2019',
				action: 'TICAD7',
				date: '28 - 30 August 2019',
				details: `Selected by Japan International Cooperation Agency (JICA), we were among companies to exhibit in TICAD7 which was held in Yokohama - Japan. An eye-opening experience into Japanese animation and GameDev industry which led to partnerships and linkages to Japan business culture.`,
				flags: [
					{
						title: 'Yokohama - Japan ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887353/images/history/japan-flag_ys0psv.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '16',
				year: '2019',
				action: 'ANIMATEKA 2019',
				date: '2 - 8 December 2019',
				details: `Animateka is the Animateka festival platform for animation professionals and students. It was conceived as a place where one can learn about the latest trends, be updated on the latest developments, and meet exciting film professionals while exploring the fantastic world of animated film!
`,
				flags: [
					{
						title: 'Ljubljana - Slovenia ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887182/images/history/slovenia-flag_bpl36k.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			},
			{
				id: '17',
				year: '2020',
				action: 'Gikoba Underground and Open Air Museum VR',
				date: '4 July 2020',
				details: `A group of few officials to a remote village in Eastern Province to launch multi-billion worth of transformational projects. Among the projects was the Gikoba Underground & Open Air Museum where we presented a Virtual Reality Visualization of the museum to the President Paul Kagame.
`,
				flags: [
					{
						title: 'Nyagatare - Rwanda ',
						image:
							'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887179/images/history/rwanda-flag_zhwpnr.svg'
					}
				],
				mainImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg'
			}
		]
	};
	render() {
		return (
			<div>
				<div className="col-md-12 special-container" id="services-beginning">
					<div className="title-text" id="tools-line">
						Our Story
					</div>
				</div>
				<div class="container-fluid px-0" id="history">
					<AllData data={this.state.data} />
				</div>
			</div>
		);
	}
}
