import React, { Component } from "react";
import AllData from "./allData"
import Footer from "../footer/index"
import "./index.scss"

class Team extends Component {
  state = {
    data: [
      {
        id: "1",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1619006317/images/team/01_y8x0gn.jpg",
        name: "NKURUNZIZA Mufuth",
        title: "Founder & CEO",
      },
      {
        id: "2",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1619006314/images/team/02_hcui3g.jpg",
        name: "KAZINTWALI Deogratias",
        title: "COO",
      },
      {
        id: "3",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1619006312/images/team/03_ya8rdt.jpg",
        name: "NTWALI Dean",
        title: "CTO",
      },
      {
        id: "4",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1619006310/images/team/04_amdxa9.jpg",
        name: "KAGIRIMANA Adam",
        title: "Lead Animator",
      },
      {
        id: "5",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1619006314/images/team/05_esjmrq.jpg",
        name: "NGANGO Igor",
        title: "Animator",
      },
      {
        id: "6",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623230794/images/team/07_cyad9d.jpg",
        name: "MUDAKIKWA J. Fred",
        title: "Web Developer",
      },
    ],
  };
  render() {
    return (
      <div>
        <div className="col-md-12 special-container" id="services-beginning">
				<div className="title-text" id="tools-line">
					Our Team
				</div>
			</div>
        <div
          className="container-fluid no-gutters"
          id="team"
        >
          <AllData data={this.state.data} />
        </div>
          <Footer />
      </div>
    );
  }
}

export default Team;
