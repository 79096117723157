import React, { Component } from "react"
import AllData from "./allCycle"
import "./index.scss"

class Cycle extends Component {
  state = {
    data: [
      {
        id: "1",
        title: "Empathise",
        desc:
          " This involves framing the question in a way that allows us to think about the target audience, what they need and will resonate with them. This gives us an insight into the audience and sets aside any assumptions that we may have had.",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887336/images/cycle/Empathyise_eo6k1z.svg",
      },
      {
        id: "2",
        title: "Define",
        desc:
          "We put together the information we have created and gathered during the Empathize stage. We immerse ourselves in the physical environment to define the problem and find inspiration on how to approach the problem.",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887335/images/cycle/Define_kfzzi0.svg",
      },
      {
        id: "3",
        title: "Ideate",
        desc:
          "We are ready to start generating ideas. Using the inspiration we gathered, we push past the obvious to make something fresh as we have come to understand the audience better. We try different ideas before going forward with one.",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887163/images/cycle/Idiate_zlcvc2.svg",
      },
      {
        id: "4",
        title: "Prototype",
        desc:
          "We quickly produce short versions of the final product with the direction we chose, make sure that the message is properly formulated and communicated. This allows us to see the constraints of the project and re-adjust the direction when needed.",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887155/images/cycle/Prototype_gbwgss.svg",
      },
      {
        id: "5",
        title: "Test",
        desc:
          "We test the product with a small audience and acquire more input. From the feedback we receive, we go back through these steps again to gain a deeper understanding of the product and the audience and iterate the product accordingly.",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887157/images/cycle/Test_v5agy3.svg",
      },
      {
        id: "6",
        title: "Release",
        desc: `All the stages of this process allow us to find the best solution that looks and feels like the experience we want to convey, we add finishing touches, polish the package and release our product.`,
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887157/images/cycle/Release_of2iqt.svg",
      },
    ],
  };
  render() {
    return (
      <div>
        <div className="col-md-12 special-container" id="cycle-beginning">
          <div className="title-text" id="tools-line">
            Cycle
          </div>
        </div>
        <div id="cycle-container">
          <div className="container special-container">
            <AllData data={this.state.data} />
          </div>
        </div>
      </div>
    );
  }
}

export default Cycle