import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

class Menu extends Component {
	handleClick = async (e) => { };
	render() {
		return (
			<div className="container" id="links" data-aos="zoom-out-up">
				<div className="nav" id="navigation">
					<div>
						<div className="link-address" id="home-link-top">
							<span className="number">/1</span>
							<span className="
            actual-link " id="home-link">
								<Link to="/#main-home">Home</Link>{' '}
							</span>{' '}
						</div>
						<div className="link-address" id="projects-link-top">
							<span className="number">/2</span>
							<span className="actual-link" id="projects-link">
								<Link to="/#project-title">Projects</Link>
							</span>
						</div>
						<div className="link-address" id="services-link-top">
							<span className="number">/3</span>
							<span className="actual-link" id="services-link">
								<Link to="/#services-beginning">Services</Link>
							</span>
						</div>
						<div className="link-address" id="cycle-link-top">
							<span className="number">/4</span>
							<span className="actual-link" id="cycle-link">
								<Link to="/#cycle-beginning">Cycle</Link>
							</span>
						</div>
						<div className="link-address" id="tools-link-top">
							<span className="number">/5</span>
							<span className="actual-link" id="tools-link">
								<Link to="/#feutured-beggining">Featured</Link>
							</span>
						</div>
						<div className="link-address" id="clients-link-top">
							<span className="number">/6</span>
							<span className="actual-link" id="clients-link">
								<Link to="/#faq-beginning">Faq</Link>
							</span>
						</div>
					</div>
				</div>
				<div className="line-art" data-aos="fade-up" />
				<div className="location" id="details-address">
					<div className="text-content">
						<div className="address-location">
							<div className="supporting">
								<div className="street">KG 11 Ave</div>
								<div className="apartment">Ikaze House 2nd Floor</div>
								<div className="address-real">Gisimenti Remera</div>
								<div className="address-real">Kigali - Rwanda</div>
							</div>
						</div>
						<div className="address-location">
							<div className="supporting">
								<div>+250 78 8488 833</div>
								<div>info@guezshow.com</div>
								<div>career@guezshow.com</div>
							</div>
						</div>

						<div className="address-location address-location-below">
							<span className="left-text">
								<div>
									<span className="text-number">/1</span>
									<span className="text-inside">
										<Link to="/history">Our Story</Link>
									</span>
								</div>
								<div>
									<span className="text-number">/3</span>
									<span className="text-inside">
										<Link to="/career">Careers</Link>
									</span>
								</div>
							</span>
							<span className="right-text">
								<div>
									<span className="text-number">/2</span>
									<span className="text-inside">
										<Link to="/about">Our Team</Link>
									</span>
								</div>
								<div>
									<span className="text-number">/4</span>
									<span className="text-inside">
										<a href=" ">Brands</a>
									</span>
								</div>
							</span>
						</div>
						<div className="address-location social-icon-time">
							<div className="address-items">
								<a href="https://www.facebook.com/GuezShow" target="_blank" rel="noreferrer">
									Fb &nbsp; &nbsp;
								</a>
							</div>
							<div className="address-items">
								<a href="https://www.instagram.com/GuezShow" target="_blank" rel="noreferrer">
									Ig &nbsp; &nbsp;
								</a>
							</div>
							<div className="address-items">
								<a href="https://www.twitter.com/GuezShow" target="_blank" rel="noreferrer">
									Tw &nbsp; &nbsp;
								</a>
							</div>
							<div className="address-items">
								<a href="https://www.linkedin.com/company/GuezShow" target="_blank" rel="noreferrer">
									In &nbsp; &nbsp;
								</a>
							</div>
							<div className="address-items">
								<a href="https://www.youtube.com/GuezShow" target="_blank" rel="noreferrer">
									Yt &nbsp; &nbsp;
								</a>
							</div>
							<a href="privacy.html">
								<div className="address-items">Terms | Privacy</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Menu;
