import React, { Component } from "react"
import AllProject from "./allProjects"
import "./index.scss"

class Projects extends Component {
  state = {
    projectsData: [
      {
        id: "1",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828073/images/projects/compressed/kanamugire/guez-show-kanamugire-closing-his-vest_tupuzc.jpg",
        name: "Kanamugire",
      },
      {
        id: "2",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828074/images/projects/compressed/the%20600/guez-show-the-600-mount-rebero-chapel-shot_cxkpon.jpg",
        name: "The 600",
      },
      {
        id: "3",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623830076/images/projects/compressed/gikoba/1b_kfohul.jpg",
        name: "Gikoba",
      },
      {
        id: "4",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828078/images/projects/compressed/cliff/guez-show-the-cliff-girl-on-moto-bike_hkznzu.jpg",
        name: "The Cliff",
      },
      {
        id: "5",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828076/images/projects/compressed/breakoutweb/guez-show-break-out-rebero-fight_klrtfr.jpg",
        name: "Break Out",
      },
      {
        id: "6",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828072/images/projects/compressed/tangashots/guez-show-tanga-model-working-on-glass_ublytj.jpg",
        name: "Tanga",
      },
    ],
  };
  render() {
    return (
      <div>
        <div className="col-md-12 special-container" id="project-title">
          <div className="title-text" id="tools-line">
            Projects
          </div>
        </div>
        <div className="container" id="projects" name="projects">
          <AllProject data={this.state.projectsData} />
        </div>
      </div>
    );
  }
}

export default Projects