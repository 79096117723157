import settings from "../../settings";
import axios from "axios";
const handleSubmit = async (e) => {
  try {
    e.preventDefault();
    document.getElementById("buttonEmail").innerHTML = "Loading...";
    const email = document.getElementById("email-input").value;
    const mutation = `mutation{
    AddEmail(userEmail: {email:"${email}"}){
        message
    }
}`;
    const data = await axios.post(
      settings.API_URL,
      {
        query: mutation,
        variables: {},
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    document.getElementById("message_sent").innerHTML =
      "Thanks For Subscribing.";
    document.getElementById("buttonEmail").innerHTML = "Done";
  } catch (error) {
    document.getElementById("buttonEmail").innerHTML = "Done";
    let errorMsg = error.response.data.errors[0].message;
    switch (errorMsg) {
      case "This is an invalid email":
        errorMsg = "This is an invalid email";
        break;
      case "this email already exist":
        errorMsg = "this email already exist";
        break;

      default:
        errorMsg = "There is a problem on our side we are fixing it.";
        break;
    }
    document.getElementById("message_error").innerHTML = errorMsg;
  }
};

export default handleSubmit;
