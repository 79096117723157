import { createSlice } from '@reduxjs/toolkit';

const careerSlice = createSlice({
	name: 'career',
	initialState: {
		career: {
			careerTitle: '',
			careerDescription: '',
			careerRequirement: [],
			careerImage: ''
		}
	},
	reducers: {
		addCareer: (state, action) => {
			state.career = action.payload.value;
            console.log(action.payload.value)
		}
	}
});

export const { addCareer } = careerSlice.actions;

export default careerSlice.reducer;
