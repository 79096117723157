import React, { Component } from "react"
import logo from "./logo.svg"
import "./index.scss"

class Logo extends Component {
    render() {
        return (
            <div className="logo-img" id="guez-show-logo">
                <a href="/"><img src={logo} className="logo-img" alt="logo of guez show" /></a>
            </div>
        )
    }
}

export default Logo