import Team from "../../components/team/index"
import React,{Component} from "react"
import {Helmet} from "react-helmet"
import HeaderImg from "../../components/headerImg/index"

class About extends Component{
    render(){
        return(<div>
                    <Helmet>
                        <meta charSet='utf-8' />
                        <title>Our Team - Guez Show</title>
                        <meta name="description" content="About page of GuezShow explore the team members we work together here at GuezShow . Here at GuezShow we believe that the best work comes with the best team work with the power of working together we help each other deliver masterpieces." />
                    </Helmet>
                    <HeaderImg title="Our Team" img="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg" icon="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1622814132/images/headerImg/Icon_Our_Team_rakqok.svg"></HeaderImg>
                    <Team />
                </div>)
    }
}

export default About