import React, { Component } from "react";
import * as THREE from "three";
import "./index.scss";

export default class WebGl extends Component {
  webgl = () => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      1,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );

    let uniforms = {
      u_time: {
        type: "f",
        value: 1.0,
      },
      u_resolution: {
        type: "v2",
        value: new THREE.Vector2(),
      },
      u_mouse: {
        type: "v2",
        value: new THREE.Vector2(),
      },
      colorB: { type: "vec3", value: new THREE.Color(0xacb6e5) },
      colorA: { type: "vec3", value: new THREE.Color(0x74ebd5) },
    };

    const renderer = new THREE.WebGLRenderer({
      canvas: document.getElementById("home-webgl"),
      antialias: true,
      alpha: true,
    });
    renderer.setPixelRatio( window.devicePixelRatio )
    renderer.setSize(window.innerWidth, window.innerHeight);
    onWindowResize();
    window.addEventListener("resize", onWindowResize, false);

    function vertexShader() {
      return `
          varying vec3 vUv; 
      
          void main() {
            vUv = position; 
      
            vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
            gl_Position = projectionMatrix * modelViewPosition; 
          }
        `;
    }
    function fragmentShader() {
      return `
     #ifdef GL_ES
precision mediump float;
#endif

uniform vec2 u_resolution;
uniform float u_time;
uniform vec2 u_mouse;
const int amount=12;

float norm(float mouseX,float mouseY){
    return(mouseX+mouseY);
}
vec3 palette(in float t,in vec3 a,in vec3 b,in vec3 c,in vec3 d)
{
    return a+b*cos(6.28318*(c*t+d));
}
vec3 toGrayscale(in vec3 color)
{
    float average=(color.r+color.g+color.b)/3.;
    return vec3(average,average,average);
}

void main(){
    vec2 coord=1.*((gl_FragCoord.xy-u_resolution)/2.)/min(u_resolution.y,u_resolution.x);
    
    float len;
    
    vec2 speed=normalize(u_mouse);
    
    // coord=coord+speed;
    
    for(int i=0;i<amount;i++){
        len=length(vec2(coord.x,coord.y));
        coord.x=coord.x-sin(coord.y)*cos(coord.y+sin(len))+cos(u_time/9.)/1.2;
        coord.y=coord.y+cos(coord.x)*sin(coord.x+cos(len))+sin(u_time/12.)/1.2;
    }
    // vec3 pallette=palette(.5,vec3(.5,.5,.5),vec3(.0588,.0431,.0431),vec3(.1137,.102,.102),vec3(.4392,.0039,.4549));
    vec3 color=vec3(cos(len),cos(len),.3);
    // color=mix(color,pallette,.4);
    color=toGrayscale(color);
    color=mix(color,vec3(.035,.035,.051),.6);
    color*=mix(color,vec3(.035,.035,.051),.9);
color/=mix(color,vec3(.035,.035,.051),.9)/vec3(3.);
color*=mix(color,vec3(.1373,.4627,.7882),.9)*vec3(.4627,.4902,.502);
    
    gl_FragColor=vec4(color,1.);
}
        `;
    }

    const geometry = new THREE.BoxGeometry();
    let material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      fragmentShader: fragmentShader(),
      vertexShader: vertexShader(),
    });
    const cube = new THREE.Mesh(geometry, material);
    scene.add(cube);

    camera.position.z = 5;
    function onWindowResize(e) {
      renderer.setSize(window.innerWidth, window.innerHeight);
      uniforms.u_resolution.value.x = renderer.domElement.width;
      uniforms.u_resolution.value.y = renderer.domElement.height;
    }

    const animate = function () {
      requestAnimationFrame(animate);

      cube.rotation.x += 0.01;
      cube.rotation.y += 0.01;

      uniforms.u_time.value += 0.05;
      if (window.innerWidth>600){
        renderer.render(scene, camera);
      } 
    };
    document.onmousemove = function (e) {
      uniforms.u_mouse.value.x = e.pageX / window.innerWidth;
      uniforms.u_mouse.value.y = e.pageY / window.innerHeight;
    };

    animate();
  };
  componentDidMount() {
    this.webgl();
  }

  render() {
    return (
      <div>
        <canvas id="home-webgl">
          <h1>ejfnsljhs,dhdsnfoli.rdhfo.l</h1>
        </canvas>
      </div>
    );
  }
}
