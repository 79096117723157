import React, { Component } from 'react';
import './index.scss';
import allData from './data';
import Footer from '../footer/index';

class ProjectDetails extends Component {
	state = {
		data: {
			title: '',
			catchz: '',
			details: '',
			headerImg: '',
			images: [
				{
					image: '',
					details: ''
				}
			],
			video: ''
		}
	};
	componentDidMount() {
		const title = this.props.match.params.title;
		const dataSets = allData(title).data;
		this.setState({ data: dataSets });
		document.getElementById('menu-item').classList.add('hide-menu');
		const anchor = document.getElementById('root');
		if (anchor) {
			anchor.scrollIntoView();
		}
	}
	handleClick = () => {
		this.props.history.goBack();
		document.getElementById('menu-item').classList.remove('hide-menu');
	};
	render() {
		console.log(this.state.data.headerImg);

		return (
			<div>
				<div className="project-unique-detail" id="kanamugire">
					<div className=" cancel-btn" onClick={this.handleClick}>
						<img
							src="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887366/images/icons/delete_z2te8c.png"
							id="tanga-cancel"
							alt="delete icon by guez show"
						/>
					</div>
					<div className="wrapper">
						<div className="header-img">
							<img src={this.state.data.headerImg} alt="header img from guez show" />
						</div>
						<div className="project-body">
							<h1>{this.state.data.title}</h1>
							<h3>{this.state.data.catchz}</h3>
							<h6>{this.state.data.details}</h6>
							<div className="row mx-0 d-flex justify-content-between">
								{this.state.data.images.map((singleData) => {
									return (
										<div className="card kanamugire" key={singleData.id}>
											<img src={singleData.image} alt={singleData.details} />
										</div>
									);
								})}
								<div className="col-md-12 vimeo">
									<iframe
										title="vimeo-player"
										src="https://player.vimeo.com/video/184810356"
										width="640"
										height="360"
										frameborder="0"
										allowfullscreen
									/>
								</div>
								<div className="bottom">
									<div className="left-arrow icon-arrow-left" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<Footer />
			</div>
		);
	}
}

export default ProjectDetails;
