import React , {Component} from "react"
import SimplexNoise from "simplex-noise" 
import * as THREE from "three"
import chroma from "chroma-js"

class FooterWebGl extends Component{
    app=(conf) =>{
        conf = {
            fov: 75,
            cameraZ: 75,
            xyCoef: 50,
            zCoef: 10,
            lightIntensity: 0.9,
            ambientColor: 0x000000,
            light1Color: 0x3399CC,
            light2Color: 0x3399CC,
            light3Color: 0x3399CC,
            light4Color: 0x3399CC,
            el:"background",
            ...conf
        };

        let renderer, scene, camera, cameraCtrl;
        let width, height, cx, cy, wWidth, wHeight;
        let box, footer_width, footer_height;
        let light1, light2, light3, light4, gArray;
        const TMath = THREE.Math;

        let plane;
        const simplex = new SimplexNoise();

        const mouse = new THREE.Vector2();
        const mousePlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
        const mousePosition = new THREE.Vector3();
        const raycaster = new THREE.Raycaster();


        init();

        function init() {
            renderer = new THREE.WebGLRenderer({ canvas: document.getElementById(conf.el), antialias: true, alpha: true });
            camera = new THREE.PerspectiveCamera(conf.fov);
            camera.position.z = conf.cameraZ;

            updateSize();
            window.addEventListener('resize', updateSize, false);

            document.addEventListener('mousemove', e => {
                const v = new THREE.Vector3();
                camera.getWorldDirection(v);
                v.normalize();
                mousePlane.normal = v;
                mouse.x = (e.clientX / width) * 2 - 1;
                mouse.y = - (e.clientY / height) * 2 + 1;
                raycaster.setFromCamera(mouse, camera);
                raycaster.ray.intersectPlane(mousePlane, mousePosition);
            });

            initScene();
            initGui();
            animate();
        }

        function initGui() {
            conf.zCoef = 42 * 25 / 100;
            conf.xyCoef = 101 - 73;
        }

        function initScene() {
            scene = new THREE.Scene();
            initLights();

            let mat = new THREE.MeshLambertMaterial({ color: 0xffffff, side: THREE.DoubleSide });
            let geo = new THREE.PlaneBufferGeometry(wWidth, wHeight, wWidth / 2, wHeight / 2);
            plane = new THREE.Mesh(geo, mat);
            scene.add(plane);

            plane.rotation.x = -Math.PI / 2 - 0.2;
            plane.position.y = -30;
            camera.position.z = 60;
        }

        function initLights() {
            const r = 30;
            const y = 10;
            const lightDistance = 500;

            light1 = new THREE.PointLight(conf.light1Color, conf.lightIntensity, lightDistance);
            light1.position.set(0, y, r);
            scene.add(light1);
            light2 = new THREE.PointLight(conf.light2Color, conf.lightIntensity, lightDistance);
            light2.position.set(0, -y, -r);
            scene.add(light2);
            light3 = new THREE.PointLight(conf.light3Color, conf.lightIntensity, lightDistance);
            light3.position.set(r, y, 0);
            scene.add(light3);
            light4 = new THREE.PointLight(conf.light4Color, conf.lightIntensity, lightDistance);
            light4.position.set(-r, y, 0);
            scene.add(light4);
        }

        function animate() {
            requestAnimationFrame(animate);

            animatePlane();
            animateLights();
            if (window.innerWidth > 600) {
              renderer.render(scene, camera);
            } 
        };

        function animatePlane() {
            gArray = plane.geometry.attributes.position.array;
            const time = Date.now() * 0.0002;
            for (let i = 0; i < gArray.length; i += 3) {
                gArray[i + 2] = simplex.noise4D(gArray[i] / conf.xyCoef, gArray[i + 1] / conf.xyCoef, time, mouse.x + mouse.y) * conf.zCoef;
            }
            plane.geometry.attributes.position.needsUpdate = true;
        }

        function animateLights() {
            const time = Date.now() * 0.001;
            const d = 50;
            light1.position.x = Math.sin(time * 0.1) * d;
            light1.position.z = Math.cos(time * 0.2) * d;
            light2.position.x = Math.cos(time * 0.3) * d;
            light2.position.z = Math.sin(time * 0.4) * d;
            light3.position.x = Math.sin(time * 0.5) * d;
            light3.position.z = Math.sin(time * 0.6) * d;
            light4.position.x = Math.sin(time * 0.7) * d;
            light4.position.z = Math.cos(time * 0.8) * d;
        }

        function updateLightsColors() {
            conf.light1Color = chroma.random().hex();
            conf.light2Color = chroma.random().hex();
            conf.light3Color = chroma.random().hex();
            conf.light4Color = chroma.random().hex();
            light1.color = new THREE.Color(conf.light1Color);
            light2.color = new THREE.Color(conf.light2Color);
            light3.color = new THREE.Color(conf.light3Color);
            light4.color = new THREE.Color(conf.light4Color);
        }

        function updateSize() {
            box = document.querySelector('footer');
            footer_width = box.offsetWidth;
            footer_height = box.offsetHeight;
            var ratio = Math.round((footer_width / footer_height));
            console.log("rattio is" + ratio);
            if (ratio >= 4) {
                ratio = 3;
            }
            if (ratio == 3) {
                ratio = 2;

            }
            if (ratio == 2) {
                ratio = 1.5;
            }
            if (ratio <= 1) {
                ratio = 0.3;
            }
            console.log("new ratio " + ratio)
            width = window.innerWidth; cx = width / 4;
            height = window.innerHeight; cy = height / 1.2;
            if (renderer && camera) {
                renderer.setSize(footer_width, footer_height);
                camera.aspect = ratio;
                camera.updateProjectionMatrix();
                const wsize = getRendererSize();
                wWidth = wsize[0];
                wHeight = wsize[1];
            }
        }

        function getRendererSize() {
            const cam = new THREE.PerspectiveCamera(camera.fov, camera.aspect);
            const vFOV = cam.fov * Math.PI / 180;
            const height = 2 * Math.tan(vFOV / 2) * Math.abs(conf.cameraZ);
            const width = height * cam.aspect;
            return [width, height];
        }
    }
    componentDidMount(){
        
        this.app()
    }
    render(){
        return(<div>
            <canvas id="background"></canvas>
        </div>)
    }
}

export default FooterWebGl