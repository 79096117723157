import React, { Component } from "react"

class AllData extends Component {
    render() {
        const { data } = this.props
        const allData = data.map(singleData => {
            return (<div className="row tool-row mx-0" key={singleData.id}>
                <div className=" col-md tool single-tool px-0">
                    <img src={singleData.image} alt="" />
                </div>
            </div>)
        })
        return (<div className="row d-flex justify-content-between  mx-0" data-aos="fade-up">
            {allData}
        </div>)
    }
}

export default AllData