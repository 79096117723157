import React, { Component } from 'react';
import Home from '../../components/home/index';
//logo and menu
import Projects from '../../components/projects/index';
import Service from '../../components/services/index';
import Cycle from '../../components/cycle/index';
import Feutured from '../../components/feutured/index';
import Faq from '../../components/faq/index';
import Footer from '../../components/footer/index';
import WebGl from '../../components/webgl/index';
import { Helmet } from 'react-helmet';
// import Scrollbar from 'smooth-scrollbar';
// import './index.scss';

class HomePage extends Component {
	componentDidMount() {
		const id = window.location.hash.substr(1);
		if (id) {
			const anchor = document.getElementById(id);
			if (anchor) {
				anchor.scrollIntoView();
			}
		}
		// const options = {
		// 	damping: 0.07,
    //   renderByPixels:true,
    //   alwaysShowTracks:true
		// };

		// Scrollbar.init(document.querySelector('#homePage'), options);
	}
	render() {
		return (
			<div id="homePage">
				<Helmet>
					<meta charSet="utf-8" />
					<title>Guez Show</title>
					<meta
						name="description"
						content="Explore different projects made by GuezShow , different services that we offer , different places we were feutured and many other informaton that help you know GuezShow and what it does. If possible leave us an email so that the next time there is an update you will be the first person to know it."
					/>
				</Helmet>
				<WebGl />
				<Home />
				<div id="other-content">
					<div id="transcroller-body" className="aos-all">
						<Projects />
						<Service />
						<Cycle />
						<Feutured />
						<Faq />
						<Footer />
					</div>
				</div>
			</div>
		);
	}
}

export default HomePage;
