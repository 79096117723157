import React from 'react';
import { useDispatch } from 'react-redux';
import { addCareer } from '../../redux/careerDetails/careerDetails';
import { useHistory } from 'react-router-dom';

const AllData = (props) => {
	const { data } = props;
	const dispatch = useDispatch();
	const history=useHistory()
	const allData = data.map((singleData) => {
		return (
			<div
				className="card"
				key={singleData.id}
				onClick={(e) => {
					e.preventDefault();
					dispatch(
						addCareer({
							value: {
								careerTitle: singleData.name,
								careerDescription: singleData.desc,
								careerRequirement: singleData.reuirement,
								careerImage: singleData.careerImage
							}
						})
					);
					history.push("/careerdetails")
				}}
			>
				<div className="career-card">
					<div className="d-flex justify-content-between">
						<h2> 0 {singleData.id}. </h2>
						<img
							src="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/careers/Download_96px_i7p0xk.png"
							alt="download career details guezshow"
						/>
					</div>
					<div className="graphic">
						<img src={singleData.image} className="illustartion" alt={singleData.desc} />
					</div>
					<h2 className="row mx-0"> {singleData.name} </h2>
				</div>
			</div>
		);
	});
	return <div className="row mx-0 d-flex justify-content-between"> {allData} </div>;
};

export default AllData;
