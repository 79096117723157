import "./index.scss"

import React from 'react'

export default function HeaderImg(props) {
    return (
        <div>
            <div className="row mx-0" id="header-img">
                <div className="col-md-12  back-wrapper" >
                    <div className="background-img" style={{backgroundImage:`url("${props.img}")`}}>
                        <h3>
                        <span>
                            <img src={props.icon} alt="" className="icon-img" />
                            {props.title}
                        </span>
                    </h3>
                    </div>
                </div>
            </div>
        </div>
    )
}
