const allData = (title) => {
  if (title === "Kanamugire") {
    return {
      data: {
        title: "Kanamugire",
        catchz: "An in house virtual character",
        details: `Hello there,
                I go by the names Kanamugire Epimake,a stylized digital human constantly being developed and improved by an
                experienced team here at Guez Show.This is the way my creators like to push the edge of there technology.
                Am fully aware of my existance as a virtual human ,and no i dont dream of electric sheep.
                Some day when i have transided the current technolgy,i would like to be an entertainment figure in the real
                world.`,
        headerImg:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828079/images/projects/compressed/kanamugire/guez-show-kanamugire-highlight-shot_lddy50.jpg",
        images: [
          {
            id: "1",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828078/images/projects/compressed/kanamugire/guez-show-kanamugire-standing-potrait-mode_bguknl.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "2",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828077/images/projects/compressed/kanamugire/guez-show-kanamugire-skin-head-close-up-shot_gpenfk.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "3",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828073/images/projects/compressed/kanamugire/guez-show-kanamugire-closing-his-vest_tupuzc.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "4",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828071/images/projects/compressed/kanamugire/guez-show-kanamugire-ambient-occulsion-pass_v5mzau.jpg",
            details: "some details about kanamugire image",
          },
        ],
        video: "https://player.vimeo.com/video/184810356",
      },
    };
  }
  if (title === "The 600") {
    return {
      data: {
        title: "The 600: The Soldiers' Story",
        catchz: "A documentary film",
        details: `The dramatic and little known story of a group of opposition soldiers trapped behind enemy lines at the
          outset of the Genocide against the Tutsi in Rwanda in 1994. Eyewitness accounts of their counterattack against
          a much larger force, and the daring rescues they made of civilians while under fire.
          Our challenge was to create shots visualizing 1994 locations of action as in history.
          This project helped build and set the current production pipeline we are using.`,
        headerImg:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828074/images/projects/compressed/the%20600/guez-show-the-600-mount-rebero-chapel-shot_cxkpon.jpg",
        images: [
          {
            id: "1",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828075/images/projects/compressed/the%20600/guez-show-the-600-mount-rebero-shot_ypscoz.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "2",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828075/images/projects/compressed/the%20600/guez-show-the-600-college-saint-andre-3d-shot-2_bsrzqa.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "3",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828075/images/projects/compressed/the%20600/guez-show-the-600-college-sant-andre-3d-shot_hooala.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "4",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828075/images/projects/compressed/the%20600/guez-show-the-600-saint-famille-3d-shot_g4jw2o.jpg",
            details: "some details about kanamugire image",
          },
        ],
        video: "https://player.vimeo.com/video/184810356",
      },
    };
  }
  if (title === "Gikoba") {
    return {
      data: {
        title: "Gikoba Underground and Open Air Museum",
        catchz: "A virtual reality representation",
        details: `Gikoba being a point of significancy as the first foot hold by RPA during Liberation war,we were tasked with
          visualizing in "Virtual Reality" a museum being built to commemorate heroes that sacrifised them selves during
          the war.This was the first time we did VR commercial project ,and so it carried much weight as the history`,
        headerImg:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623830076/images/projects/compressed/gikoba/1b_kfohul.jpg",
        images: [
          {
            id: "1",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623830074/images/projects/compressed/gikoba/MUSEUM_BUNKER_ENTRANCE_uxffi4.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "2",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623830073/images/projects/compressed/gikoba/camouflage3_pzs1jp.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "3",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623830072/images/projects/compressed/gikoba/bunker_information_area_CORRECTED_rmdt6n.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "4",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623830071/images/projects/compressed/gikoba/3_htcrxf.jpg",
            details: "some details about kanamugire image",
          },
        ],
        video: "https://player.vimeo.com/video/184810356",
      },
    };
  }
  if (title === "The Cliff") {
    return {
      data: {
        title: "The Cliff",
        catchz: "A technical test short animation",
        details: `In the year 2167, where the whole world is a desert wasteland, June must race to the top of a seemingly
          self-sustaining rock to discover its mysteries.

          Synopsis
          In the year 2167, where the whole world is a desert wasteland, Div discovers a self sustaining rock. When she
          gets to the rock, she encounters Adam and the two have to fight to get to the top. Div stab her and Rus
          immediately blasts him off the rock.
          Still in production.`,
        headerImg:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828078/images/projects/compressed/cliff/guez-show-the-cliff-cover-image_vx70fc.jpg",
        images: [
          {
            id: "1",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828078/images/projects/compressed/cliff/guez-show-the-cliff-girl-on-moto-bike_hkznzu.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "2",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828085/images/projects/compressed/cliff/guez-show-the-cliff-villain-shot_calqtg.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "3",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828081/images/projects/compressed/cliff/guez-show-the-cliff-vilain-superhero-landing_evpddx.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "4",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828079/images/projects/compressed/cliff/guez-show-the-cliff-real-russ_omkgyn.jpg",
            details: "some details about kanamugire image",
          },
        ],
        video: "https://player.vimeo.com/video/184810356",
      },
    };
  }
  if (title === "Break Out") {
    return {
      data: {
        title: "The 600 : 3Bn Breakout",
        catchz: "A museum film",
        details: `On 7 April 1994 around 3 PM, Major General Paul Kagame ordered the RPA 3rd Battalion to break out of its
          position in CND to defend itself and rescue victims in its vicinity.
          Our task was to illustrate the history using motion graphics and 3d rendered representation of victims being
          rescued,soldiers in action and the different locations of significancy.
          The main challenge as far as technicalities go,was to render amazing shots fast.This, we tackeled by using a
          variety of optimized tools that helped us render vast terrains with a variety vegetation.`,
        headerImg:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828077/images/projects/compressed/breakoutweb/guez-show-break-out-soldier-fighting_om1otx.jpg",
        images: [
          {
            id: "1",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828079/images/projects/compressed/breakoutweb/guez-show-break-out-rpf-inkontanyi-soldier-maching_lhehd2.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "2",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828077/images/projects/compressed/breakoutweb/guez-show-rpf-inkontanyi-rescuing-people_ymyghz.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "3",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828076/images/projects/compressed/breakoutweb/guez-show-break-out-rebero-fight_klrtfr.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "4",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828076/images/projects/compressed/breakoutweb/guez-show-break-out-house-burning-during-genocide_sgrfmv.jpg",
            details: "some details about kanamugire image",
          },
        ],
        video: "https://player.vimeo.com/video/184810356",
      },
    };
  }
  if (title === "Tanga") {
    return {
      data: {
        title: "Vunzo Azura Ashura",
        catchz: "A virtual fashion model",
        details: `This was our third try on digital humans.
we worked closely with an experienced fashion designer "TANGA" from a well known fashion house "TANGA DESIGNS".
After a few iterations in both character and cloth designs, we came up with a character that is some what stylized.
The choice helped us avoid the uncanny valley.
We hope to keep pushing our digital humans as they have come to be in great demand especially in the fashion world.`,
        headerImg:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828081/images/projects/compressed/tangashots/guez-show-tanga-landscape-shot_vuiemt.jpg",
        images: [
          {
            id: "1",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828072/images/projects/compressed/tangashots/guez-show-tanga-model-working-on-glass_ublytj.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "2",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828082/images/projects/compressed/tangashots/guez-show-tanga-model-cloth-details-shot_tpzljf.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "3",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828071/images/projects/compressed/tangashots/guez-show-tanga-model-taking-a-fashion-pose_xtxrde.jpg",
            details: "some details about kanamugire image",
          },
          {
            id: "4",
            image:
              "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1623828072/images/projects/compressed/tangashots/guez-show-tanga-model-walking-on-glass-shot_ncadmf.jpg",
            details: "some details about kanamugire image",
          },
        ],
        video: "https://player.vimeo.com/video/184810356",
      },
    };
  }
};

export default allData;
