import React, { Component } from "react"
import AllData from "./allData"
import "./index.scss"

class Feutured extends Component {
  state = {
    data: [
      {
        id: "1",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1626337654/images/feutured/CNN_cnckeb.svg",
      },
      {
        id: "2",
        image: "https://www.globaltimes.cn/img/logo1@3x.png",
      },
      {
        id: "3",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1618994084/images/feutured/logo_grpzgv.svg",
      },
      {
        id: "4",
        image:
          "https://res.cloudinary.com/dbkjsfg3t/image/upload/v1622826115/images/headerImg/Design-tweaks_ctyuzl.svg",
      },
    ],
  };
  render() {
    return (
      <div>
        <div
          className="col-md-12 special-container custom-margin"
          id="feutured-beggining"
        >
          <div className="title-text" id="tools-line">
            Featured
          </div>
        </div>
        <div className="container" id="clients">
          <AllData data={this.state.data} />
        </div>
      </div>
    );
  }
}

export default Feutured