import React, { Component } from 'react';
import AllData from './allData';
import CareerFaq from './faq';
import Footer from '../footer/index';
import './index.scss';
import HeaderImg from '../headerImg/index';

class Career extends Component {
	state = {
		data: [
			{
				id: '1',
				name: '3D Generalist',
				image: 'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png',
				desc: `Guez Show needs experienced CG generalists looking with strong all-around skills that can adapt to the needs of the project.`,
				reuirement: [
					`Ability to complete various tasks depending on the creative and technical needs of a project`,
					`Model, texture, light and render 3D Assets`,
					`Basic Rigging skills`,
					`Basic layout and camera animation skills`
				],
				careerImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png'
			},
			{
				id: '2',
				name: 'Asset Artist',
				image: 'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png',
				desc: `Guez Show is looking for an artist with the ability to model and texture 3D assets as needed by the project.`,
				reuirement: [
					`Ability to Research, Model, Texture, light and render 3D Assets`,
					`Ability to follow direction from 2D Concept Art/Creative Briefs`,
					`Mastery of at least 1 Industry standard 3D Package (Maya, Blender, Houdini)`,
					`Create Clean and Efficient models`,
					`Efficiency in UV Layout`,
					`Basic knowledge of ZBrush, Substance Painter or equivalents`
				],
				careerImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png'
			},
			{
				id: '3',
				name: 'Environment Artist',
				image: 'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png',
				desc: `An artist that can create photo-real and stylized environments using a mix of 3d models, projections and matte painting`,
				reuirement: [
					`Ability to follow direction from 2D Concept Art/Creative Briefs`,
					`Mastery of at least 1 Industry standard 3D Package (Maya, Blender, Houdini)`,
					`Create Clean and Efficient models`,
					`Efficiency in UV Layout`,
					`Ability to optimize scene’s resource consumption and minimize render time`
				],
				careerImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png'
			},
			{
				id: '4',
				name: 'Character Artist',
				image: 'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png',
				desc: `The Character artist is responsible for creating characters that are ready for texturing and animation. The characters can range from realistic or stylized humans to animals and creatures.`,
				reuirement: [
					`Ability to follow direction from 2D Concept Art/Creative Briefs`,
					`Mastery of at least 1 Industry standard 3D Package (Maya, Blender, Houdini)`,
					`Create Clean and Efficient models`,
					`Efficiency in UV Layout`,
					`Aesthetic eye for appealing characters`
				],
				careerImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png'
			},
			{
				id: '5',
				name: 'Animator',
				image: 'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png',
				desc: `Animators are responsible for animating both realistic and stylized characters. They work closely with character artists and rigging TDs in the creation process.`,
				reuirement: [
					`Ability to research and follow direction from 2D Concept Art/Creative Briefs`,
					`Mastery of at least 1 Industry standard 3D Package (Maya, Blender, Houdini)`,
					`Ability to create life-like motion for both stylized and realistic animation`,
					`Strong Problem-solving skills to deal with challenging characters and scenarios`
				],
				careerImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png'
			},
			{
				id: '6',
				name: 'Surfacing Artist',
				image: 'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png',
				desc: `Surfacing artists deal with creating surface materials and texturing of 3D assets and characters. They work closely with modeling, lighting and rendering artists for optimization.`,
				reuirement: [
					`Aesthetic eye for appeal`,
					`Strong sense of balance between beauty and practicality.`,
					`Ability to work under any particular workflow constraint`,
					`Lighting skills for LookDev purposes`
				],
				careerImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png'
			},
			{
				id: '7',
				name: 'Lighting Designer',
				image: 'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png',
				desc: `Lighting artists create CG lighting for full CG shots and integration of CG elements into live action for VFX and Animation. They optimize render set-ups for efficient render time usage and Collaborate with leads from other disciplines to meet challenging production deadlines`,
				reuirement: [
					`Extensive knowledge and experience with Arnold for Maya`,
					`An understanding of Multi-Pass 3D Compositing`,
					`Strong time management skills and the ability to balance priorities under the pressure of a deadline-driven production`
				],
				careerImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png'
			},
			{
				id: '8',
				name: 'Production TD',
				image: 'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png',
				desc: `A production TD deals with problem-solving technical issues that come up in the production environment. They work closely with the FX team and all other assets as they go upstream and oversee the successful render of the shots`,
				reuirement: [
					`Extensive knowledge and experience with Maya and Arnold`,
					`Strong problem-solving skills regarding`,
					`Demonstrate technical prowess in creating scripts and tools to facilitate an efficient workflow.`,
					`Experience and knowledge in Python programming language`,
					`Ability to code review and troubleshoot problems as they arise.`,
					`Ability to quickly acquire a working understanding of off-the-shelf and proprietary software tools.`
				],
				careerImage:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png'
			}
		],
		faqData: [
			{
				id: '1',
				image:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887159/images/careers/Page_Overview_3_64px_bicrh1.png',
				imag_desc: 'format icon by guezshow',
				title: 'How can I apply for a job?',
				description:
					'You can send your application to our email info@guezshow.com. Tell us about yourself, what you want to do with a link to your portfolio. You can also reach us on Social Media, hang out. We would like to hear from you.'
			},
			{
				id: '2',
				image:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887160/images/careers/Video_File_64px_le4h6z.png',
				imag_desc: 'video icon for guezshow',
				title: 'How can I send my portfolio or Demo Reel?',
				description:
					'Provide a link to your portfolio on a File sharing platform of your choice. Ensure that the link stays valid for at least a year as we may need to revisit your application later.'
			},
			{
				id: '3',
				image:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887160/images/careers/Property_Time_50px_avwmve.png',
				imag_desc: 'time icon by guezshow',
				title: 'Which post should I apply for?',
				description:
					'You should look for the post that best matches your skills. Highlight your best skills but also include other areas you can work in. We highly value a diverse portfolio and even if that particular job is not available, we may approach you with something else you are suitable for.'
			},
			{
				id: '4',
				image:
					'https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887160/images/careers/Repeat_48px_vtwrun.png',
				imag_desc: 'reload icon by guezshow',
				title: 'How often should I apply?',
				description:
					'We might not be hiring at the moment you send your application but your application is reviewed and added to our talent pool and we may call you when an opportunity arises. You can also subscribe to our newsletter to be alerted for roles that match your skillset.'
			}
		]
	};
	render() {
		return (
			<div>
				<HeaderImg
					title="All Careers"
					img="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg"
					icon="https://res.cloudinary.com/dbkjsfg3t/image/upload/v1622814132/images/headerImg/Icon_Our_Story_copy_2_hfdkt0.svg"
				/>
				<div className="container-fluid" id="careers">
					<h1 className="text-capitalize">Available careers</h1>
					<AllData data={this.state.data} />
					<div class="common-question">
						<h1 class="text-capitalize">Frequently Asked Questions</h1>
						<CareerFaq data={this.state.faqData} />
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default Career;
