import React,{Component} from "react"

class AllData extends Component{
    render(){
        const {data} =this.props
        const allData=data.map(singleData =>{
            return(
                <div className="col-md-4 " key={singleData.id}>
                    <img src={singleData.image} alt={singleData.imag_desc} />
                    <h3>{singleData.info}...&nbsp; &nbsp;<span
                            className="blue-color">Learn
                            More</span>
                    </h3>
                </div>
            )
        })
        return(
            <div className="row logos justify-content-center">
                {allData}
            </div>
        )
    }
}

export default AllData