import React, { Component } from "react";
import Career from "../../components/career/index";
import {Helmet} from "react-helmet"

class CareerPage extends Component {
  render() {
    return (
      <div>
        <Helmet>
            <meta charSet='utf-8' />
            <title>Careers - Guez Show</title>
            <meta name="description" content="Do you want to grow your career in computer generated imagery use our career page to see what skills we want so that you can train and whenever we hire the next time you could then be able to join our team at GuezShow." />
        </Helmet>
        <Career></Career>
      </div>
    );
  }
}

export default CareerPage;
