import React, { Component } from "react";
import "./teamcard.scss";

class AllData extends Component {
  render() {
    const { data } = this.props
    const allDataOut = data.map(singleData => {
      return (
        <div className=" card"  key={singleData.id} id="team-card">
          <div className="cards" style={{backgroundImage:`url(${singleData.image})`}}>
            <div className="row d-flex justify-content-start">
              <div className="number px-0">10{singleData.id} </div>
              <div className="postion">
                <div className="name">{singleData.name}</div>
                <div className="title">{singleData.title}</div>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return <div className="row no-gutters d-flex justify-content-between">{allDataOut}</div>;
  }
}

export default AllData;
