import React, { Component } from 'react'
import Footer from "../footer/index"
import "./index.scss"

export default class Privacy extends Component {
    render() {
        return (
            <div>
                <div className="container-fluid px-0">
         <div className="row privacy" id="kanamugire">
             <div className="wrapper">
                 
                 <div className="project-body">
                     <h1>Kanamugire</h1>
                     <h3>For immersive virtual experience</h3>
                     <h6>
                         <h1>
                            WEBSITE TERMS OF USE AGREEMENT
                         </h1>
                         <p>WELCOME TO WWW.GUEZGOUP.COM, THE OFFICIAL WEBSITE (WWW.GUEZGROUP.COM) FOR The Guez Group Ltd (“COMPANY”). THE FOLLOWING TERMS ALONG WITH THE DISCLAIMER AND PRIVACY POLICY SERVE AS THE AGREEMENT GOVERNING THE VISITOR’S USE OF THIS WEBSITE AND OUR CORPORATE FAMILY WEBSITES. THE PARTIES TO THIS AGREEMENT INCLUDE “COMPANY” WHICH WE MAY REFER TO AS “WE” OR “US” AND THE VISITOR TO THE SITE, WHO WE MAY REFER TO AS “YOU.”</p>
                         <p>BY USING THIS SITE YOU WARRANT THAT YOU ARE 18 YEARS OF AGE OR OLDER.</p>
                         <p>PLEASE READ THE FOLLOWING. BY ACCESSING, VIEWING, OR USING THIS SITE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE WITH THESE TERMS. IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS, PLEASE DO NOT USE THIS SITE.</p>
                         <p>THESE TERMS APPLY TO BOTH WEBSITES (INCLUDING THE REST OF OUR CORPORATE FAMILY) AND ANYTHING REQUESTED THROUGH OUR SITE</p>
                         <h1>USE OF SITE</h1>
                         <p>We hope you enjoy using this website as it is meant to provide you with information about immersive virtual experiences. In addition to providing you with information, the other purposes of our website include, allowing you to join our mailing list, provide a means for you to contact us. Any other use of this site is prohibited.</p>
                         <p>You agree not to use any features of this site that permit communications, display, or otherwise communicate any of the following:
                             <ol>
                                 <li>any defamatory, threatening, obscene, harassing, or otherwise unlawful information;</li>
                                 <li>any advertisement, solicitation, or spam;</li>
                                 <li>any encouragement of illegal activity;</li>
                                 <li>unauthorized use or disclosure of private, personally identifiable information of others;</li>
                                 <li>any materials subject to trademark, copyright, or other laws protecting any materials or data of others in the absence of a valid license or other right to do so; or</li>
                                 <li>any false or misleading information.</li>
                             </ol>
                         </p>
                         <h1>
                            TERM AND TERMINATION
                         </h1>
                         <p>Without limiting its other remedies, Company may immediately discontinue, suspend, terminate, or block your and any user’s access to this site at any time in Company’s sole discretion.</p>
                         <h1>SITE CONTENTS AND OWNERSHIP</h1>
                         <p>Company owns the intellectual property rights of all information on this site including but not limited to the company name, logo, graphics, videos, audios, images, designs, photographs, writings, graphs, data, and other materials. Company’s ownership rights are protected by copyrights, trademarks, trade secrets, or other proprietary rights. You shall comply with all copyright laws worldwide in your use of this website and prevent unauthorized copying. You may not copy, display, distribute, modify, reproduce, or transmit this site or portions thereof without prior written consent from Company. Except as provided in this Agreement, Company does not grant you any express or implied right in or under any patents, trademarks, copyrights, or trade secret information.</p>
                         <h1>INFORMATION YOU PROVIDE TO US</h1>
                         <p>You agree that any information you provide to us through email, comments, or other forms of communication, is done with a non-exclusive, worldwide, perpetual, irrevocable, royalty-free license to use however we see fit. Please do not provide us with information you do not want us to use.</p>
                         <h1>LINKED WEBSITES</h1>
                         <p>
                            Company may provide links to third party websites (“Linked Sites”). If you choose to click on one of those links, you are leaving Company’s website, and you do so at your own risk. It is your responsibility to take all protective measures to guard against viruses or other destructive elements. Linked Sites, regardless of the linking form are not controlled by Company. Company cannot make any representations or warranties about the opinions expressed, nature, content, accuracy, security, completeness, or reliability of the information provided, or regarding the products or services provided on the Linked Sites. Links do not imply that Company sponsors or endorses the Linked Site. Except for links to information authored by Company, Company is neither responsible for nor will it be liable under any theory based on (i) any Linked Site; (ii) any information and/or content found on any Linked Site; or (iii) any site(s) linked to or from any Linked Site. If you decide to visit any Linked Sites and/or transact any business on them, you do so at your own risk. Please contact the webmasters of any Linked Sites concerning information, goods, and/ or services appearing on them.
                         </p>
                         <h1>ELECTRONIC COMMUNICATIONS AND ELECTRONIC SIGNATURES</h1>
                         <p>You agree to be bound by any affirmation, assent, or agreement you transmit through this website, including but not limited to any consent you give to receive communications from Company solely through electronic transmission. You agree that when in the future you click on an “I agree,” “I consent,” or other similarly worded “button” or entry field with your mouse, keystroke, or other computer device, your agreement or consent will be legally binding and enforceable and the legal equivalent of your handwritten signature.</p>
                         <h1>LIMITATION OF LIABILITIES</h1>
                         <p>YOU AGREE THAT COMPANY AND ITS PROVIDERS SHALL NOT BE LIABLE FOR ANY DAMAGE, LOSS, OR EXPENSE OF ANY KIND ARISING OUT OF OR RESULTING FROM YOUR POSSESSION OR USE OF THE MATERIALS, CONTENT, OR INFORMATION ON THIS SITE REGARDLESS OF WHETHER SUCH LIABILITY IS BASED IN TORT, CONTRACT, OR OTHERWISE. IN NO EVENT, INCLUDING, WITHOUT LIMITATION, A NEGLIGENT ACT, SHALL COMPANY OR ANY OF ITS PROVIDERS BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES (INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS, LOSS OR CORRUPTION OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, OR INTERRUPTION OF BUSINESS), ARISING OUT OF OR IN ANY WAY RELATED TO THE MATERIALS, CONTENT, OR INFORMATION ON THIS SITE OR ANY OTHER PRODUCTS, SERVICES, OR INFORMATION OFFERED, SOLD, OR DISPLAYED ON THIS SITE, YOUR USE OF, OR INABILITY TO USE, THIS SITE GENERALLY, OR OTHERWISE IN CONNECTION WITH THIS AGREEMENT, REGARDLESS OF WHETHER COMPANY OR ANY OF ITS PROVIDERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES DO NOT ALLOW THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</p>
                         <h1>LIMITATIONS ON CLAIM</h1>
                         <p>Any cause of action you may have with respect to your use of this site must be commenced within six months after the claim or cause of action arises.</p>
                         <h1>INDEMNIFICATION</h1>
                         <p>
                            You agree to indemnify, defend, and hold harmless Company, its affiliates, agents, employees, and licensors from and against any and all claims and expenses, including reasonable attorney fees, arising out of or related in any way to your use of the site, violation of this Agreement, violation of any law or regulation, or violation of any proprietary or privacy right.
                         </p>
                         <h1>REFUNDS</h1>
                         <p>Company does not offer refunds on any products or services requested through this website.</p>
                         <h1>DISPUTE RESOLUTION</h1>
                         <p>This Agreement is governed by and shall be construed in accordance with the laws of the Republic of Rwanda. You agree to submit to the personal and exclusive jurisdiction in Rwanda for any disputes with Company arising out of your use of this site. You agree that if a dispute arises, such dispute will be settled by the competent courts. </p>
                         <h1>ENTIRE AGREEMENT</h1>
                         <p>This Agreement constitutes the entire agreement between Company and you with respect to this website. This Agreement supersedes and cancels all prior or contemporaneous discussions, writings, negotiations, and agreements whether electronic, oral, or written between you and Company with respect to this website.

                            A printed version of this Agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based on or relating to this Agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</p>
                        <h1>SEVERABILITY</h1>
                        <p>If any term in this Agreement is found to be void or voidable, the remaining terms of the Agreement are unaffected, and deemed to remain in full force and effect, including those terms that are similar.</p>
                        <h1>NO WAIVER</h1>
                        <p>company’s failure to enforce its rights under this Agreement or take action against any party for breach of this Agreement does not constitute a waiver of such rights, or of future subsequent enforcement of such rights.</p>
                        <h1>MODIFICATIONS TO AGREEMENT</h1>
                        <p>Company may revise this Agreement at any time. Your continued use of this site, our products and services serve as an agreement that you agree to be bound by the revised Agreement. Company’s modifications will become effective on the date they are first posted to this site. Company does not have any obligation to notify you of changes to this Agreement. It is your responsibility to review the terms to apprise yourself of modifications.
                        </p>
                        <h1>ASSIGNMENT OF RIGHTS </h1>
                        <p>Your rights under this Agreement are not assignable.</p>
                        <h1>CONTACT:</h1>
                        <p>Should you have questions regarding this Terms of Use Agreement, please contact ………@……...</p>
                        <h1>WEBSITE DISCLAIMER</h1>
                        <p>By using this website or any information on it, including but not limited to blogs, videos, audios, social media posts, products, and services, you acknowledge that you have read and agree to the terms in this disclaimer. If you do not agree with these terms, DO NOT USE THIS WEBSITE.</p>
                        <h1>ASSUMPTION OF RISK</h1>
                        <p>You expressly agree that the use of this website is done at your own risk. The information published on this website is for informational purposes only. Any reliance you place on such, you do at your own risk. You understand that while great care is taken to provide you with the best information possible, Guez Group or its corporate family (“Company”) makes no representations or warranties of any kind, express or implied, about the reliability, accuracy, completeness, security, or currency of the information provided.</p>
                        <h1>INFORMATIONAL PURPOSES ONLY</h1>
                        <p>The information provided on this website is for informational purposes only. In no way is the information provided meant to be a substitute for professional legal or financial advice. If you require legal or financial services, it is your responsibility to seek it out from a licensed attorney or financial services professional.</p>
                        <h1>NO WARRANTIES</h1>
                        <p>THIS WEBSITE AND THE INFORMATION, CONTENT, AND MATERIALS ON THIS WEBSITE ARE PROVIDED ON AN “AS IS,” “WHERE IS,” AND “WHERE AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE WEBSITE, THE CONTENT, INFORMATION, OR THE MATERIALS ON THIS WEBSITE. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF ANY KIND, WITH RESPECT TO ANY OF THE MATERIALS, CONTENT, OR INFORMATION ON THIS WEBSITE OR ANY SERVICES, GOODS, OR OTHER PRODUCTS OFFERED, SOLD, OR DISPLAYED ON THIS WEBSITE OR YOUR USE OF THIS WEBSITE GENERALLY, INCLUDING WARRANTIES OF MERCHANTABILITY, ACCURACY OF INFORMATION, QUALITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES, SO THE ABOVE DISCLAIMER MAY NOT APPLY TO THE EXTENT SUCH JURISDICTION’S LAW APPLIES TO THIS AGREEMENT.</p>
                     </h6>
                     
                 </div>
             </div>
             
         </div>
    </div>
                <Footer></Footer>
            </div>
        )
    }
}
