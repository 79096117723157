import React, { useEffect } from "react";
import reactGa from "react-ga";
import { useLocation } from "react-router-dom";
const RouteChangeTracker = ({ history }) => {
  const location = useLocation();
  useEffect(() => {
    reactGa.initialize("UA-196323179-1");
    reactGa.pageview(location.pathname);
    reactGa.event({
      category: "User",
      action: `Visited a page called ${location.pathname}`,
    });
  });

  return <div></div>;
};

export default RouteChangeTracker;
