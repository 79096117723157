import React, { Component } from 'react'
import Privacy from "../../components/privacy/index"

export default class PrivacyPage extends Component {
    render() {
        return (
            <div>
                <Privacy></Privacy>
            </div>
        )
    }
}
